<template>
  <div class="app">
    <div class="feedback-form">
      <Form :model="feedbackForm" :rules="feedbackRules" ref="feedbackForm">
        <Row type="flex" justify="center">
          <Col span="4">
            <div class="feedback-words">
              帮助与反馈
            </div>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span="20">
            <FormItem label="问题反馈" style="margin-top: 3vh;" prop="feedback">
              <Row>
                <Col span="20">
                  <Input v-model="feedbackForm.feedback" type="textarea" :autosize="{minRows: 4,maxRows: 8}"
                         placeholder="请输入你的反馈"/>
                </Col>
              </Row>
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span="20">
            <FormItem label="联系方式" prop="contract">
              <Row>
                <Col span="20">
                  <Input v-model="feedbackForm.contract" type="text"
                         placeholder="请输入你的联系方式,QQ或微信号，手机号（必填）"/>
                </Col>
              </Row>
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span="6">
            <FormItem style="margin-top: 3vh">
              <Button size="large" :style="buttonStyle" class="login_buttonBg" @click="feedbackSubmit('feedbackForm')"
                      type="primary" long>
                提交
              </Button>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </div>
  </div>
</template>

<script>
  export default {
    name: "feedback",
    components: {},
    data() {
      return {
        feedbackForm: {
          feedback: "",
          contract: ""
        },
        // 按钮的样式
        buttonStyle: {
          color: '#fff'
        },
        // 表单验证
        feedbackRules: {
          contract: [
            {required: true, message: '联系方式不能为空'},
          ],
          feedback: [
            {required: true, message: '反馈内容不能为空'},
          ],
        }
      }
    },
    methods: {
        feedbackSubmit(name) {
          this.$refs[name].validate(valid => {
            if (valid) {
              let token = this.common.getAuth();
              let userInfo = this.common.getStorage(this.CONSTANTS.USER_INFO);
              let obj = Object.assign({"login_id": userInfo.login_id, "user_name": vname}, this.feedbackForm);
              this.$axios.post(`${this.apiUrlCros}/api/feedback/submit`, obj, {
                "headers": {
                  "Eova-Authorization": token
                }
              }).then(res => {
                if (res.data.code == "200") {
                  this.$Message.success("提交成功,感谢你的反馈");
                  this.$router.push({name:"index"});
                } else {
                  this.$Message.error(res.data.message);
                  if (res.data.code === "6000") {
                    this.$router.push({name:"login"});
                  }
                }
              }).catch(err => {
                this.$Message.error(err);
              })
            }
          });
        }
    },
    created() {

    },
    mounted() {

    }
  }
</script>

<style scoped lang="less">
  .feedback-form {
    width: 40vw;
    height: 28vh;
    background: #fff;
    margin-top: 20vh;
    margin-left: 20vw;
  }

  .feedback-words {
    text-align: center;
    font-size:24px;
    font-weight:800;
    line-height:36px;
    margin-top: 1vh;
  }
</style>
